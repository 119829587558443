import React from 'react';

import { BannerSlot, useAdsContext } from '@/core/lib/ads/ads.context';
import Hero from '@/next/components/Hero';

const HomeLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { Ad } = useAdsContext();

  return (
    <main id="app" className="flex w-full flex-col items-center bg-bg-primary">
      <Hero />
      <div className="flex w-full flex-col items-center bg-bg-primary px-4 py-3 md:px-6 lg:py-6 xl:px-24 xl:py-8">
        <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-8 md:gap-5 lg:grid-cols-12 lg:gap-6">
          <div className="col-span-2 md:col-span-8 lg:col-span-8">{children}</div>
          <div className="col-span-4 hidden justify-center lg:flex">
            <div className="sticky right-0 top-[84px] size-fit">
              <Ad size="336x600" slot={BannerSlot.DONNONS_HOME_SIDE} media="lg" isStartAuction />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeLayout;
